import React from "react";
import SEO from "../components/SEO";
import { Hero, Navbar } from "../tailwind";
import { Features } from "../tailwind/Features";
import { Footer } from "../tailwind/Footer";
import { faqHandler } from "../tailwind/faqs/faq.handler";

export default ({ navigate, location, pageContext }) => {
  const { product: { name, phone, heading, articles, footer, paths, slug } } = pageContext;

  return (
    <div style={{ backgroundColor: '#F9FBFD'}}>
      <SEO title={name} />
      <Navbar phoneNumber={phone}/>
      <Hero navigate={navigate} location={location} paths={paths} heading={heading}/>
      <Features name={name}/>
      {faqHandler(slug).index()}
      <Footer/>
    </div>
  )
};